import Spinner from "react-bootstrap/Spinner";
import React, { useState } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { DEFAULT_API_CONFIG } from "../services/api/api-config";
import Cookies from "js-cookie";

function removeItem(arr, item) {
  return arr.filter(f => f !== item)
}

const arrayToString = (array, key) => {
  console.log("Array: ", array)
  if (!array) return "";
  return array.map(function (element) {
    console.log("Element: ", element)
    return element.data.name
  }).join(", ");
}

const arrayToStringWithKey = (array, key) => {
  if (!array) return "";
  return array.map(function (element) {
    console.log("Element: ", element)
    return element[key]
  }).join(", ");
}

const getBadge = status => {
  switch (status) {
    case true: return 'success'
    case false: return 'secondary'
    default: return 'primary'
  }
}


const getPublicPrivateBadge = status => {
  switch (status) {
    case true: return 'success'
    case false: return 'danger'
    default: return 'primary'
  }
}

const showLoaderInTableRow = () => {
  return(
    <td>
      <Spinner animation="border" role="status" size={"sm"}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </td>
  )
}

const showSuccessToast  = (msg) => {
  toast.success(msg)
}

const showErrorToast = (msg) => {
  toast.error(msg)
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

function getRequest (endpoint, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  axios.get(DEFAULT_API_CONFIG.url + endpoint, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function postRequest(endpoint, body, success, error, extraHeader) {

  const cookie = Cookies.get('access_token');
  const cookieBussiness = Cookies.get('id');
  let headers;
  if (cookie !== null && cookie !== undefined) {
    headers = { Authorization: `Bearer ${Cookies.get('access_token')}` };
    if (extraHeader) {
      headers = { ...headers, ...extraHeader }
    }
  }
  axios.post(DEFAULT_API_CONFIG.url + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if (response.status !== 200) {
        if (!cookieBussiness) {
          showErrorToast(response.data?.data[0]?.message)
        }

      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function putRequest (endpoint, body, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  console.log("Cookies: ", cookie)
  console.log("Headers: ", headers)
  axios.put(DEFAULT_API_CONFIG.url + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if(response.status!==200){
        showErrorToast(response.data?.data[0]?.message)
      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function patchRequest (endpoint, body, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  console.log("Cookies: ", cookie)
  console.log("Headers: ", headers)
  axios.patch(DEFAULT_API_CONFIG.url + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if(response.status!==200){
        showErrorToast(response.data?.data[0]?.message)
      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function getPracticeRequest (endpoint, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  axios.get(DEFAULT_API_CONFIG.url1 + endpoint, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function postPracticeRequest (endpoint, body, success,error, extraHeader){
  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
    if(extraHeader){
      headers = {...headers, ...extraHeader}
    }
  }
  axios.post(DEFAULT_API_CONFIG.url1 + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if(response.status!==200){
        showErrorToast(response.data?.data[0]?.message)
      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function putPracticeRequest (endpoint, body, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  console.log("Cookies: ", cookie)
  console.log("Headers: ", headers)
  axios.put(DEFAULT_API_CONFIG.url1 + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if(response.status!==200){
        showErrorToast(response.data?.data[0]?.message)
      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function patchPracticeRequest (endpoint, body, success,error){

  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  console.log("Cookies: ", cookie)
  console.log("Headers: ", headers)
  axios.patch(DEFAULT_API_CONFIG.url1 + endpoint,body, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
      if(response.status!==200){
        showErrorToast(response.data?.data[0]?.message)
      }
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

function deletePracticeRequest (endpoint, success,error){
  const cookie = Cookies.get('access_token');
  let headers;
  if(cookie!==null && cookie!==undefined) {
    headers = {Authorization: `Bearer ${Cookies.get('access_token')}`};
  }
  axios.delete(DEFAULT_API_CONFIG.url1 + endpoint, {headers, validateStatus: () => true}
  )
    .then(function (response) {
      success(response)
    })
    .catch(function (error) {
      showErrorToast(error)
    });
}

const getUserType = () => Cookies.get('user_type')
const getUserName = () => Cookies.get('full_name')
const getUsersSubject = () => Cookies.get('subject_assigned')
const isSocketConnected = () => Cookies.get('socket_connected')

export { removeItem, arrayToString, arrayToStringWithKey, getBadge, showLoaderInTableRow, useForceUpdate, getPublicPrivateBadge, showSuccessToast, showErrorToast, validateEmail, getRequest, postRequest, putRequest, patchRequest, getPracticeRequest,postPracticeRequest, putPracticeRequest, patchPracticeRequest, deletePracticeRequest, getUserType, getUserName, getUsersSubject, isSocketConnected }
