import { createStore } from 'redux'
import {ACTIVE_QUESTION, ARCHIVED_QUESTION, CLEAR_QUESTION, LOGGED_IN_STATUS, NEW_QUESTION} from "./action-types";

const initialState = {
  sidebarShow: 'responsive',
  new_questions: [],
  active_questions: [],
  archived_questions:[],
  isLoggedIn: false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case NEW_QUESTION:
      return Object.assign({}, state, {
        new_questions: [rest.payload.newQuestion, ...state.new_questions]
      });
    case ACTIVE_QUESTION:
      return Object.assign({}, state, {
        active_questions: [rest.payload.activeQuestions, ...state.active_questions]
      });
    case ARCHIVED_QUESTION:
      return Object.assign({}, state, {
        archived_questions: [rest.payload.archivedQuestions, ...state.archived_questions]
      });
    case CLEAR_QUESTION:
      return Object.assign({}, state, {
        new_questions: []
      });
    case LOGGED_IN_STATUS:
      // console.log("Login Status: ", rest.payload )
      return Object.assign({}, state, {
        isLoggedIn: rest.payload
      });
    default:
      return state
  }
}

const store = createStore(changeState,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store
