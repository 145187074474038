// import * as env from "../../environment-variables"

// /**
//  * The options used to configure the API.
//  */
// export interface ApiConfig {
//     /**
//      * The URL of the api.
//      */
//     url: string
//
//     /**
//      * Milliseconds before we timeout the request.
//      */
//     timeout: number,
//
//     mockServer: boolean
// }

/**
 * The default configuration for the app.
 */
require('dotenv').config();
console.log("new change");

console.log({env: process.env});
console.log("new change");

const url1 = process.env.REACT_APP_URL1;
export const DEFAULT_API_CONFIG = {
  // url: "http://127.0.0.1:3000",                // local
  // socketUrl: "http://127.0.0.1:3003",          // local
  qmsurl: "http://127.0.0.1:3001",
  // url: "http://3.7.232.66:3002",            //stage
  // socketUrl: "http://3.7.232.66:3003",      //stage
  url: "https://api.doubtbuddy.com",
  // url1: "https://uat.practiceapi.doubtbuddy.com", // prod
  url1,
  

   
  socketUrl: "https://chat.doubtbuddy.com", // prod
  timeout: 4000,
  mockServer: false,
};
