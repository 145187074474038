import {ACTIVE_QUESTION, ARCHIVED_QUESTION, CLEAR_QUESTION, LOGGED_IN_STATUS, NEW_QUESTION} from "./action-types";

export function addNewQuestion(payload) {
  console.log("Doubt in payload: ", payload)
  return { type: NEW_QUESTION, payload };
}

export function addActiveQuestion(payload) {
  return { type: ACTIVE_QUESTION, payload };
}

export function addArchivedQuestion(payload) {
  return { type: ARCHIVED_QUESTION, payload };
}

export function clearQuestions(){
  return { type: CLEAR_QUESTION};
}

export function setLoggedInStatus(payload) {
  // console.log("Login Status: ", payload)
  return { type: LOGGED_IN_STATUS, payload };
}
